import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation
import shape from '../images/footer.jpg'; // Assuming the shape image is stored in the assets folder

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="relative bg-black text-white py-12 overflow-hidden">
      <img src={shape} alt="Shape" className="absolute inset-0 w-full h-full object-cover opacity-50" />
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="mb-6 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">Let's Talk!</h3>
            <p className='mb-4'>webachacompanyltd@gmail.com</p>
            <p className='mb-4'>+254 700134945</p>
            <p className='mb-4'>Webuye, Kenya.</p>
            <p>PO Box: 1225 Webuye</p>
          </div>
          <div className="mb-6 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">What We Do?</h3>
            <ul>
              <li className='mb-4'><Link to="/bamboo-planting" className="hover:text-secondary">Bamboo Planting</Link></li>
              <li className='mb-4'><Link to="/bamboo-processing" className="hover:text-secondary">Bamboo Processing</Link></li>
              <li className='mb-4'><Link to="/bamboo-products" className="hover:text-secondary">Bamboo Products Selling</Link></li>
              <li><Link to="/contact" className="hover:text-secondary">Contact Us</Link></li>
            </ul>
          </div>
          <div className="mb-6 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">Check Our Pages</h3>
            <ul>
              <li className='mb-4'><Link to="/" className="hover:text-secondary">Home</Link></li>
              <li className='mb-4'><Link to="/about" className="hover:text-secondary">About Us</Link></li>
              <li className='mb-4'><Link to="/services" className="hover:text-secondary">Services</Link></li>
              <li><Link to="/products" className="hover:text-secondary">Products</Link></li>
              <li><Link to="/impact" className="hover:text-secondary">Our Impact</Link></li>
            </ul>
          </div>
          <div className="mb-6 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">WEBACHA COMPANY LTD</h3>
            <p className='mb-4'>Innovation</p>
            <p className='mb-4'>Conservation</p>
            <p>Livelihood</p>
          </div>
        </div>
        <hr className='text-white mt-12 opacity-25' />
        <div className="flex justify-center mt-10 space-x-6">
          <a href="https://facebook.com/webacha" target="_blank" rel="noopener noreferrer" className="hover:text-secondary bg-primary text-white p-3 rounded-full"><FaFacebookF /></a>
          <a href="https://www.instagram.com/webacha_limited/" target="_blank" rel="noopener noreferrer" className="hover:text-secondary bg-primary text-white p-3 rounded-full"><FaInstagram /></a>
          <a href="https://x.com/WebachaS" target="_blank" rel="noopener noreferrer" className="hover:text-secondary bg-primary text-white p-3 rounded-full"><FaTwitter /></a>
          <a href="https://www.linkedin.com/company/webacha-company-limited/" target="_blank" rel="noopener noreferrer" className="hover:text-secondary bg-primary text-white p-3 rounded-full"><FaLinkedinIn /></a>
          <a href="https://www.tiktok.com/@webacha_bamboo" target="_blank" rel="noopener noreferrer" className="hover:text-secondary bg-primary text-white p-3 rounded-full"><FaTiktok /></a>
        </div>
        <div className="text-center mt-10">
          <p className='text-sm'>© WEBACHA COMPANY LIMITED {currentYear}. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
