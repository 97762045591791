import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from './ScrollAnimation';

const Hero = () => {
  return (
    <ScrollAnimation>
    <div className="relative h-128 sm:h-96 md:h-128 lg:h-screen bg-cover bg-center " style={{ backgroundImage: "url('./images/Herosec.jpg')" }}>
      <div className="absolute inset-0 opacity-50 animate-pulse"></div>
      <div className="relative flex flex-col items-center justify-center h-full text-white text-center px-4">
          <p className="text-sm sm:text-base uppercase mb-4 text-secondary font-bold">Webacha Company Ltd</p>
          <h1 className="text-xl sm:text-xl md:text-5xl font-bold leading-tight mb-12">Promoting the eco-friendly switch using bamboo</h1>
          <ScrollAnimation>
          <div className="flex flex-col md:flex-row md:space-x-8 space-y-4 md:space-y-0 mb-2 md:mb-0">
            <Link to="/contact">
              <button className="sm:text-sm mb-4 bg-secondary hover:bg-primary text-white font-semibold py-2 px-8 rounded w-full md:w-auto">Contact Us</button>
            </Link>
            <Link to="/services">
              <button className=" sm:text-sm bg-primary hover:bg-secondary text-white font-semibold py-2 px-8 rounded w-full md:w-auto">Our Services</button>
            </Link>
          </div>
          </ScrollAnimation>
      </div>
    </div>
    </ScrollAnimation>
  );
};

export default Hero;
