import React from 'react';
import ScrollAnimation from './ScrollAnimation';
import { FaShippingFast, FaShoppingCart, FaHeadset, FaUndo, FaSeedling, FaPaintBrush, FaBoxOpen } from 'react-icons/fa';

const WhyChooseUs = () => {
  return (
    <ScrollAnimation>
    <div className="flex flex-col lg:flex-row items-center py-20 px-6 overflow-hidden">
      <div className="lg:w-1/2 mx-6">
        <h2 className="md:text-3xl text-xl text-primary font-bold mb-4">Why Choose Us</h2>
        {/* <p className="text-textSecondary mb-6">We harness skills to empower sustainability and
eco-friendly living through Bamboo Innovation
</p> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-12">
          <div className="flex items-start">
            <FaSeedling className="text-4xl text-primary mr-4" />
            <div>
              <h3 className="md:text-xl text-md font-bold text-secondary">Sustainable Practices</h3>
              <p className="text-textSecondary">Bamboo is one of the fastest-growing plants on earth, making it a highly renewable resource. By choosing our products, you are supporting sustainability.</p>
            </div>
          </div>
          <div className="flex items-start">
            <FaPaintBrush className="text-4xl text-primary mr-4" />
            <div>
              <h3 className="md:text-xl text-md font-bold text-secondary">Quality Assurance</h3>
              <p className="text-textSecondary">We ensure that only the best bamboo is selected, processed, and packaged, meeting set quality standards.</p>
            </div>
          </div>
          <div className="flex items-start">
            <FaBoxOpen className="text-4xl text-primary mr-4" />
            <div>
              <h3 className="md:text-xl text-md font-bold text-secondary">Versatility of Products</h3>
              <p className="text-textSecondary">
              From bamboo tiles to furniture, ice cream sticks, décor and disposable cutlery, we offer a diverse selection of bamboo products to meet various needs. </p>
            </div>
          </div>
          <div className="flex items-start">
            <FaUndo className="text-4xl text-primary mr-4" />
            <div>
              <h3 className="md:text-xl text-md font-bold text-secondary">Commitment to Community</h3>
              <p className="text-textSecondary">
              Our business operations adhere to high ethical sourcing standards, ensuring fair practices and social responsibility.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 flex justify-center mt-6 lg:mt-0  md:ml-12 ml-2">
        <img src="images/beauty.jpeg" alt="Decorative" className="max-w-full h-auto rounded-lg" />
      </div>
    </div>
    </ScrollAnimation>
  );
};

export default WhyChooseUs;
