import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const ContactSection = () => {
  return (
    <section id="contact-section" className="py-16 bg-gradient-to-r from-primary to-secondary text-center text-white overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="text-lg font-bold mb-6 text-secondary">Contact Us Today</h2>
        <h3 className="text-2xl font-bold mb-8 ">Let us discuss your needs.</h3>
        <div className="flex justify-center items-center md:space-y-0 md:space-x-4">
            <Link to="/contact">
              <button className="bg-primary text-white py-2 px-6 rounded font-semibold flex items-center">
                Our Services <FaArrowRight className="ml-4 bg-white rounded-full text-primary text-sm" />
              </button>
            </Link>
          </div>
      </div>
    </section>
  );
};

export default ContactSection;
