import React, { useState } from 'react';
import Slider from 'react-slick';
import { FaQuoteLeft } from 'react-icons/fa';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import TestimonialForm from './TestimonialForm';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../index.css'; // Import the CSS file
import ScrollAnimation from './ScrollAnimation';

export const initialTestimonials = [
  {
    name: 'Darly Wijay',
    quote: 'webacha products are 100% beautiful',
    company: 'Cheri Beauty',
    image: 'images/bamboo1.jpeg',
    rating: 4.5,
  },
  {
    name: 'Justina Renée',
    quote: 'I like their customer service.',
    company: 'Eco Customers',
    image: 'images/bamboo1.jpeg',
    rating: 5,
  },
  {
    name: 'Brian Erikson',
    quote: 'The products are designed just the way I wanted them',
    company: '5-star',
    image: 'images/bamboo1.jpeg',
    rating: 4,
  },
];

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState(initialTestimonials);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const addTestimonial = (testimonial) => {
    setTestimonials([testimonial, ...testimonials]);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <BsStarFill key={`full-${i}`} className="text-yellow-500" />
        ))}
        {halfStar && <BsStarHalf className="text-yellow-500" />}
        {[...Array(emptyStars)].map((_, i) => (
          <BsStar key={`empty-${i}`} className="text-yellow-500" />
        ))}
      </>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <ScrollAnimation>
    <section id="testimonials" className="py-20 bg-background overflow-hidden">
      <div className="container mx-auto px-4">
        <h2 className="md:text-4xl text-2xl font-bold text-center mb-12 text-primary"><span className='text-accent'>What Our</span> Happy Clients Says</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="px-4">
              <div className="testimonial-card bg-white p-6 rounded-lg shadow-lg text-center relative">
                <FaQuoteLeft className="text-primary text-3xl absolute top-4 left-4 transform -translate-y-1/2" />
                <div className="mb-4 flex justify-center">
                  <img src={testimonial.image} alt={testimonial.name} className="w-16 h-16 rounded-full shadow-lg" />
                </div>
                <p className="mb-4 italic testimonial-quote">"{testimonial.quote}"</p>
                <h4 className="text-xl font-bold mb-2">{testimonial.name}</h4>
                <p className="text-gray-600">{testimonial.company}</p>
                <div className="flex justify-center mt-4">{renderStars(testimonial.rating)}</div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-center mt-12">
          <button
            onClick={openForm}
            className="bg-primary text-white px-6 py-3 rounded-lg shadow-lg hover:bg-primary-dark transition duration-300"
          >
            Submit Testimonial
          </button>
        </div>
        {isFormOpen && <TestimonialForm onClose={closeForm} addTestimonial={addTestimonial} />}
      </div>
    </section>
    </ScrollAnimation>
  );
};

export default Testimonials;
