// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import ContactInfo from './components/ContactInfo';
import Services from './components/Services';
import Products from './components/Products';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import WhyChooseUs from './components/WhyChooseUs';
import FollowUs from './components/FollowUs';
import WhatWeOffer from './components/WhatWeOffer';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import ContactPage from './pages/ContactPage'; // Make sure you have a ContactPage component
import ProductsPage from './pages/ProductsPage';
import ServicesPage from './pages/ServicesPage';
import OurImpact from './pages/OurImpact';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <ContactInfo />
              <WhyChooseUs />
              <Services />
              <Products />
              <FollowUs />
              <Contact />
              <Testimonials />
              {/* < WhatWeOffer /> */}
            </>
          } />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/bamboo-products" element={<ProductsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/impact" element={<OurImpact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
