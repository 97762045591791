import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import solarPump from '../images/furniture6.jpeg';
import pumpingInverter from '../images/furniture3.jpeg';
import chargingInverter from '../images/dinner1.jpeg';
import decor from '../images/bike.jpeg';
import bag from '../images/furniture9.jpeg';
import dinner from '../images/furniture16.jpeg';
import chandaliers from '../images/beauty.jpeg';
import tiles from '../images/furniture14.jpeg';
import baskets from '../images/furniture5.jpeg';
import holder from '../images/furniture7.jpeg';
import bucket from '../images/furniture11.jpeg';
import furniture from '../images/furniture1.jpg';
import furnitures from '../images/furniture13.jpeg';
import item from '../images/item6.jpeg';
import item1 from '../images/item1.jpeg';
import item2 from '../images/item4.jpeg';
import item3 from '../images/item2.jpeg';

// Import slick-carousel css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from '../components/ScrollAnimation'; // Import the ScrollAnimation component

const ProductsPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Slower transition speed
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const headerSettings = {
    dots: true,
    infinite: true,
    speed: 2000, // Slower transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500, // Slower autoplay speed
    fade: true,
    cssEase: 'linear',
  };

  const headerImages = [
    '/images/furniture7.jpeg',
    '/images/item6.jpeg',
    '/images/bike.jpeg',
    '/images/item5.jpeg',
    '/images/item2.jpeg',
    '/images/furniture6.jpeg',
    '/images/furniture3.jpeg',
    '/images/furniture11.jpeg',
    '/images/furniture5.jpeg',

  ];

  return (
    <div className="container mx-auto">
      <header className="relative text-white text-center overflow-hidden">
        <Slider {...headerSettings}>
          {headerImages.map((image, index) => (
            <div key={index} className="relative">
              <img src={image} alt={`Slide ${index}`} className="w-full h-64 md:h-96 md:object-none object-cover" />
              <div className="absolute inset-0 bg-gray-200 bg-gradient-to-l from-primary via-primary to-secondary opacity-25"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
                <h1 className="text-3xl md:text-6xl font-bold">Products</h1>
              </div> 
            </div>
          ))}
        </Slider>
      </header>

        <section className="text-center py-20 bg-white mb-8 mt-6 overflow-hidden">
          <div className="container mx-auto">
            <h2 className="text-4xl font-semibold mb-12 mt-6 text-primary">
              <span className="text-accent">Our</span> Products
            </h2>
            <ScrollAnimation>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:grid-cols-4 px-8">
              <ProductCard image={solarPump} title="Cutlery" description="Beautiful Bamboo cutlery." />
              <ProductCard image={pumpingInverter} title="Furniture" description="Get long-lasting bamboo furniture" />
              <ProductCard image={decor} title="Bamboo Bike" description="Enjoy your ride with bamboo made bike." />
              <ProductCard image={bag} title="Wall Decor" description="Elegant wall decors available." />
              <ProductCard image={chargingInverter} title="Dining Set" description="Well made bamboo dining set." />
              <ProductCard image={holder} title="Bamboo Baskets" description="Traditionally crafted bamboo holders available." />
              <ProductCard image={chandaliers} title="Chandaliers" description="Light your home with bamboo made chandaliers." />
              <ProductCard image={tiles} title="Tiles" description="Strong long-lasting bamboo tiles." />
              <ProductCard image={baskets} title="Bamboo baskets" description="Beautiful bamboo made baskets." />
              <ProductCard image={bucket} title="Laundry Basket" description="Grab Your laundry basket." />
              <ProductCard image={furniture} title="Bamboo furniture" description="Strong, beautiful bamboo furniture." />
              <ProductCard image={furnitures} title="Chair" description="Get bamboo chairs of all designs." />
              <ProductCard image={item} title="Bamboo Finishing" description="Comfortable workstation and office furniture." />
              <ProductCard image={item1} title="Bamboo Cutlery" description="Get bamboo cutleries of all designs." />
              <ProductCard image={item2} title="Bamboo Cutlery" description="Get bamboo Cutleries for your kitchen." />
              <ProductCard image={item3} title="Bamboo Cutlery" description="Get bamboo Cutleries for your kitchen." />
            </div>
            </ScrollAnimation>

          </div>
        </section>

      <ScrollAnimation>
        <section className="text-primary text-center py-16 mb-4">
          <h2 className="text-2xl font-semibold mb-8">Order Now For Quick Delivery</h2>
          <Link to="/contact">
            <button className="bg-secondary hover:bg-accent text-white font-bold py-2 px-4 rounded-full">
              Contact Us
            </button>
          </Link>
        </section>
      </ScrollAnimation>
    </div>
  );
};

const ProductCard = ({ badge, image, title, description }) => (
  <ScrollAnimation>
    <div className="bg-secondary border rounded-lg shadow-sm w-full text-center relative flex flex-col justify-between h-full">
      {badge && (
        <div
          className="absolute top-6 -left-2 bg-primary text-white text-xs px-2 py-1 rounded"
          style={{ transform: 'rotate(-60deg)' }}
        >
          {badge}
        </div>
      )}
      <img
        src={image}
        alt={title}
        className="w-full h-40 md:h-56 lg:h-64 object-cover mb-4 rounded-t-lg"
      />
      <div className="px-6 flex flex-col flex-grow">
        <h3 className="text-xl font-bold mb-2 text-primary text-left">{title}</h3>
        <p className="text-sm text-primary mb-4 text-left flex-grow">{description}</p>
        <div className="flex items-left mb-4">
          <button
            onClick={handleBuyNowClick}
            className="bg-primary px-4 py-1 rounded-md text-white"
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  </ScrollAnimation>
);

const companyWhatsAppNumber = '+254700134945';
const defaultMessage = 'Hello, I am interested in your products!';

const handleBuyNowClick = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const whatsappUrl = isMobile
    ? `https://wa.me/${companyWhatsAppNumber}?text=${encodeURIComponent(defaultMessage)}`
    : `https://web.whatsapp.com/send?phone=${companyWhatsAppNumber}&text=${encodeURIComponent(defaultMessage)}`;

  if (isMobile) {
    window.open(whatsappUrl, '_blank');
  } else {
    if (window.location.hostname.includes('web.whatsapp.com')) {
      window.location.href = whatsappUrl;
    } else {
      window.open(whatsappUrl, '_blank');
    }
  }
};

export default ProductsPage;
