// src/components/ContactInfo.js

import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ContactInfo = () => {
  return (
    <section id="contact-info" className="py-10 bg-white overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="flex flex-col w-full md:flex-row items-center justify-between my-6 gap-6">
          <div className="flex items-center space-x-4 w-full md:w-1/3 ">
            <div className="w-full md:w-auto md:px-8">
              <h3 className="text-xl font-bold text-primary flex items-center md:mb-2 mb-4">
                <FaPhoneAlt className="text-primary text-2xl mr-6 " />Contact Information
              </h3>
              <p className="text-textSecondary px-12"> +254 700134945</p>
              <p className="text-textSecondary px-12 "> webachacompanyltd@gmail.com</p>
            </div> 
          </div>
          <div className="flex items-center space-x-4 w-full mb-6">
            <div className="w-full md:w-auto">
              <h3 className="text-xl font-bold text-primary flex items-center md:mb-2 mb-4">
                <FaMapMarkerAlt className="text-primary text-2xl mr-6" />Our Location
              </h3>
              <p className="text-textSecondary px-12">Webuye, Kenya</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-start justify-between md:mt-24">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 px-4 flex flex-col justify-start">
            <img src="images/furniture3.jpeg" alt="webacha" className="rounded-lg shadow-lg mb-4 md:mb-0" />
          </div>
          <div className="w-full md:w-1/2 md:pl-8 px-4 flex flex-col justify-start">
            <h3 className="md:text-3xl font-bold text-primary mb-4 mt-0 text-xl">
              Our Mission
            </h3>
            <p className="text-textSecondary mb-12 ">
              We harness the incredible potential of bamboo to create a positive impact on both people and planet.
            </p>

            <h3 className="md:text-3xl font-bold text-primary mb-4 mt-6 text-xl">
              Our Vision
            </h3>
            <p className="text-textSecondary mb-12">
              Empowering Sustainability and Eco-Friendly Living Through Bamboo Innovation.
            </p>

            <blockquote className="border-l-4 border-accent pl-4 mb-6">
              <p className="text-primary italic mb-6">
                "Our goal is to create sustainable and nature-friendly solutions for both future and present generations."
              </p>
              <p className="text-primary font-bold">
                <span className='text-accent'>- Githui Kimani,</span> Co-founder & Chief Bamboo Officer.
              </p>
            </blockquote>
            <Link to="/about">
              <button className="bg-primary text-white px-8 py-2 rounded-sm mt-4 text-sm">
                Read More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
