import React from 'react';
import product1 from '../images/furniture7.jpeg';
import product2 from '../images/furniture1.jpg';
import product3 from '../images/furniture9.jpeg';
import product4 from '../images/item2.jpeg';
import product5 from '../images/item6.jpeg';
import product6 from '../images/item5.jpeg';
import ScrollAnimation from './ScrollAnimation';

const products = [
  { title: 'Bamboo Holders', description: 'Get beautiful Long lasting multi-purpose holders', image: product1 },
  { title: 'Bamboo Furniture', description: 'Elevate your home aesthetics with 100% bamboo made furniture.', image: product2 },
  { title: 'Cutlery', description: 'With great art and design, we make cutlery with bamboo.', image: product4 },
  { title: 'Bamboo Finishing', description: 'With great art and design, we make your Office a peaceful and soothing place to work in.', image: product5 },
  { title: 'Utensils Organizer', description: 'With great art and design, we make you great home essentials with bamboo.', image: product6 },
  { title: 'Wall Decor', description: 'With great art and design, we make your home a peaceful and soothing place with bamboo.', image: product3 },
];

const companyWhatsAppNumber = '+254700134945'; // Replace with the company's WhatsApp number
const defaultMessage = 'Hello, I am interested in your products!'; // Replace with your default message

const handleBuyNowClick = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const whatsappUrl = isMobile 
    ? `https://wa.me/${companyWhatsAppNumber}?text=${encodeURIComponent(defaultMessage)}` 
    : `https://web.whatsapp.com/send?phone=${companyWhatsAppNumber}&text=${encodeURIComponent(defaultMessage)}`;

  if (isMobile) {
    window.open(whatsappUrl, '_blank');
  } else {
    if (window.location.hostname.includes('web.whatsapp.com')) {
      window.location.href = whatsappUrl;
    } else {
      window.open(whatsappUrl, '_blank');
    }
  }
};

const Products = () => {
  return (
    <ScrollAnimation>
    <section id="products" className="py-20 bg-gray-100 overflow-hidden">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12 text-primary"><span className='text-accent'>Our </span> Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div key={index} className="bg-gray-100 rounded-lg shadow-md">
              <img src={product.image} alt={product.title} className="h-60 w-full object-cover rounded-t-lg mb-4" />
              <h3 className="md:text-2xl text-xl font-bold mb-6 mt-6 mx-12 text-primary">{product.title}</h3>
              <p className="mb-12 mx-12">{product.description}</p>
              <button 
                onClick={handleBuyNowClick} 
                className="bg-primary text-white px-6 py-2 rounded-md mb-12 mx-12"
              >
                Buy Now
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
    </ScrollAnimation>
  );
};

export default Products;
