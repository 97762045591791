import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ScrollAnimation from '../components/ScrollAnimation';

const OurImpact = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  const sdgList = [
    { title: "Decent Work and Economic Growth", description: "We create jobs and foster economic growth through our bamboo operations, ensuring fair wages and safe working conditions." },
    { title: "Industry, Innovation, and Infrastructure", description: "Our advanced bamboo processing and manufacturing facilities drive innovation and support sustainable industrialization." },
    { title: "Sustainable Cities and Communities", description: "Our eco-friendly bamboo products contribute to green urban development and improved quality of life in cities." },
    { title: "Responsible Consumption and Production", description: "We promote sustainable consumption and production by responsibly sourcing and efficiently processing bamboo, reducing waste." },
    { title: "Climate Action", description: "Our bamboo plantations sequester carbon and help mitigate climate change, supporting global climate action efforts." },
    { title: "Life on Land", description: "We enhance biodiversity and prevent soil erosion through our bamboo plantations, supporting land conservation and restoration." },
    { title: "Partnerships for the Goals", description: "We collaborate with communities, governments, and organizations to advance the SDGs, sharing knowledge and resources for greater impact." },
  ];

  const sdgIndices = [8, 9, 11, 12, 13, 15, 17];

  return (
    <div className="container mx-auto">
      {/* Header Section */}
      <div className="relative w-full h-64 sm:h-96 mb-8">
        <img src="images/item11.jpeg" alt="Header" className="w-full h-full object-cover" />
        <div className="absolute inset-0 opacity-50 flex items-center justify-center bg-gradient-to-l from-primary via-primary to-secondary">
          <div className="relative z-10 text-center">
            <h1 className="text-white text-4xl sm:text-6xl font-bold">Our Impact</h1>
          </div>
        </div>
      </div>
    <ScrollAnimation>
      <div className="max-w-4xl mx-auto mb-2 px-6">
        <p className="text-textSecondary mb-2">Our foundation rests on sustainable practices and generating positive social and environmental impact.</p>
      </div>
    </ScrollAnimation>

      {/* SDG Section */}
      <div className="max-w-4xl mx-auto py-8 px-6">
        <h2 className="text-lg font-bold mb-8 text-primary">Webacha is an SDG Contributor:</h2>
        {/* <div className="flex flex-wrap justify-between items-center gap-4 mb-8">
  <img src="images/sdg8.jpg" alt="SDG 8" className="w-16 h-16 sm:w-24 sm:h-24 sm:flex" />
  <img src="/images/SDG9.jpg" alt="SDG 9" className="w-16 h-16 sm:w-24 sm:h-24 sm:flex" />
  <img src="/images/SDG11.jpg" alt="SDG 11" className="w-16 h-16 sm:w-24 sm:h-24 sm:flex" />
  <img src="/images/sdg12.jpg" alt="SDG 12" className="w-16 h-16 sm:w-24 sm:h-24 sm:flex" />
  <div className="w-full flex justify-start gap-6 sm:w-auto sm:flex-1 sm:justify-between sm:gap-0">
    <img src="/images/SDG13.jpg" alt="SDG 13" className="w-16 h-16 sm:w-24 sm:h-24 " />
    <img src="/images/SDG15.jpg" alt="SDG 15" className="w-16 h-16 sm:w-24 sm:h-24" />
    <img src="/images/SDG17.jpg" alt="SDG 17" className="w-16 h-16 sm:w-24 sm:h-24" />
  </div>
</div> */}
<ScrollAnimation>
<div className="flex flex-wrap justify-between items-center gap-4 mb-8">
  <img src="images/sdg8.jpg" alt="SDG 8" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
  <img src="/images/SDG9.jpg" alt="SDG 9" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
  <img src="/images/SDG11.jpg" alt="SDG 11" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
  <img src="/images/sdg12.jpg" alt="SDG 12" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
  <div className="flex w-full justify-start sm:w-auto sm:justify-between gap-6 sm:gap-8">
    <img src="/images/SDG13.jpg" alt="SDG 13" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
    <img src="/images/SDG15.jpg" alt="SDG 15" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
    <img src="/images/SDG17.jpg" alt="SDG 17" className="w-16 h-16 sm:w-24 sm:h-24 flex-none" />
  </div>
</div>



        <div className="space-y-4">
          {sdgList.map((sdg, index) => (
            <div key={index} className="border border-gray-300 py-6 px-8 mb-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleDropdown(index)}
              >
                <h3 className="text-md font-bold text-textSecondary">SDG {sdgIndices[index]} – {sdg.title}</h3>
                <span className='text-sm'>{openDropdown === index ? <FaChevronUp /> : <FaChevronDown />}</span>
              </div>
              {openDropdown === index && (
                <div className="mt-2">
                  <p>{sdg.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default OurImpact;
