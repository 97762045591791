import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaInstagram, FaClock, FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin, FaTiktok } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import ScrollAnimation from '../components/ScrollAnimation';


const ContactPage = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data) => {
    const serviceID = 'your_service_id';
    const templateID = 'your_template_id';
    const userID = 'your_user_id';

    emailjs.send(serviceID, templateID, data, userID)
      .then((response) => {
        console.log('Email sent:', response);
        setSubmitted(true);
        reset();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div className="container mx-auto overflow-hidden">
      {/* Header Section */}
      <ScrollAnimation className='animate_fadeIn'>
      <header className="relative text-white py-36 text-center">
        <div className="absolute inset-0">
          <img src="images/about1.jpeg" alt="Header Background" className="w-full h-full relative bg-cover bg-center object-cover" />
          <div className="absolute inset-0 opacity-50 bg-gradient-to-r from-primary via-primary to-secondary"></div>
        </div>
        <div className="relative">
          <h1 className="text-3xl md:text-6xl font-bold">Contact Us</h1>
        </div>
      </header>
      </ScrollAnimation>

      {/* Contact Section */}
      <ScrollAnimation>
      <section className="my-12 py-20 ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-4">
          {/* Contact Information */}
          <div className='px-6'>
          <h2 className="text-2xl font-bold mb-4 text-primary">Get In Touch</h2>
          <p className='text-textSecondary text-sm mb-12'>Thank you for your interest in Webacha Company Limited. We are happy to serve and respond to your Enquiries</p>
          <div className="space-y-6">
    <div className="flex items-center space-x-4 p-12 border border-gray-300 rounded-lg shadow-sm">
      <FaMapMarkerAlt className="text-secondary text-4xl" />
      <div >
        <h3 className="font-semibold text-primary text-xl">Location</h3>
        <p className='text-textSecondary text-sm'>Webuye, Kenya</p>
      </div>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <div className="flex items-center space-x-4 p-12 border border-gray-300 rounded-lg shadow-sm">
        <FaPhone className="text-secondary text-4xl" />
        <div>
          <h3 className="font-semibold text-primary text-xl">Phone</h3>
          <a href='tel:0700134945
' className='text-textSecondary text-sm'>0700134945</a>
        </div>
      </div>
      <div className="flex items-center space-x-4 p-1 border border-gray-300 rounded-lg shadow-sm">
        <FaEnvelope className="text-secondary text-4xl" />
        <div>
          <h3 className="font-semibold text-primary text-xl">Email</h3>
          <p className='text-textSecondary text-sm'>webachacompanyltd@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
{/* working hours */}
  <div className="mt-8">
  <h2 className="md:text-2xl text-lg font-bold mb-4 text-primary">Working Hours</h2>
  <div className="flex flex-col md:flex-row md:items-center md:space-x-6 mb-16">
    <div className="flex items-center space-x-2  mb-2 md:mb-0">
      <FaClock className="text-secondary" />
      <p className="text-textSecondary text-sm">Mon – Fri: 8.00 A.M – 5.00 P.M</p>
    </div>
    <div className="flex items-center space-x-2">
      <FaClock className="text-secondary" />
      <p className="text-textSecondary text-sm">Sat: 8.00 A.M – 2.00 P.M</p>
    </div>
  </div>
</div>

  {/* Social Icons */}
  <div className="flex space-x-1 md:space-x-4 mt-4">
    <a href="https://www.facebook.com/webacha" target="_blank" rel="noopener noreferrer" className="bg-primary text-white p-3 rounded-full">
      <FaFacebook className="text-3xl cursor-pointer" />
    </a>
    <a href="https://x.com/WebachaS" target="_blank" rel="noopener noreferrer" className="bg-primary text-white p-3 rounded-full">
      <FaTwitter className="text-3xl cursor-pointer" />
    </a>
    <a href="https://www.instagram.com/webacha_limited/" target="_blank" rel="noopener noreferrer" className="bg-primary text-white p-3 rounded-full">
      <FaInstagram className="text-3xl cursor-pointer" />
    </a>
    <a href="https://www.linkedin.com/company/webacha-company-limited/" target="_blank" rel="noopener noreferrer" className="bg-primary text-white p-3 rounded-full">
      <FaLinkedin className="text-3xl cursor-pointer" />
    </a>
    <a href="https://www.tiktok.com/@webacha_bamboo/" target="_blank" rel="noopener noreferrer" className="bg-primary text-white p-3 rounded-full">
      <FaTiktok className="text-3xl cursor-pointer" />
    </a>
  </div>
</div>


          {/* Contact Form */}
          <div className="bg-primary text-white md:p-16 py-16 px-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-8 text-secondary ">Send Us A Message</h2>
            <div className='mb-12'>
              <h3 className='text-2xl font-bold mb-2 '>Your Details</h3>
              <p className='text-secondary mb-6'>We'll Get Back To You Soonest</p>
              <hr className='border-sm'></hr>
            </div>
            {submitted ? (
              <div className="p-4 bg-green-500 text-white rounded">
                Thank you for your message. We will get back to you soon.
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col mb-4">
                    <label htmlFor="firstName" className="font-semibold">First Name<span className='text-accent'>*</span></label>
                    <input
                      type="text"
                      id="firstName"
                      {...register('firstName', { required: true })}
                      className="mt-1 p-4 rounded-md border-none focus:outline-none text-textSecondary focus:ring-0"
                    />
                    {errors.firstName && <span className="text-secondary">First name is required</span>}
                  </div>
                  <div className="flex flex-col mb-4">
                    <label htmlFor="lastName" className="font-semibold">Last Name<span className='text-accent'>*</span></label>
                    <input
                      type="text"
                      id="lastName"
                      {...register('lastName', { required: true })}
                      className="mt-1 p-4 rounded-md border-none text-textSecondary focus:ring-0"
                    />
                    {errors.lastName && <span className="text-secondary">Last name is required</span>}
                  </div>
                  <div className="flex flex-col mb-4">
                    <label htmlFor="email" className="font-semibold">Email<span className='text-accent'>*</span></label>
                    <input
                      type="email"
                      id="email"
                      {...register('email', { required: true })}
                      className="mt-1 p-4 rounded-md border-none text-textSecondary focus:ring-0"
                    />
                    {errors.email && <span className="text-secondary">Email is required</span>}
                  </div>
                  <div className="flex flex-col mb-4">
                    <label htmlFor="phone" className="font-semibold">Phone<span className='text-accent'>*</span></label>
                    <input
                      type="tel"
                      id="phone"
                      {...register('phone', { required: true })}
                      className="mt-1 p-4 rounded-md border-none text-textSecondary focus:ring-0"
                    />
                    {errors.phone && <span className="text-secondary">Phone number is required</span>}
                  </div>
                  <div className="md:col-span-2 flex flex-col mb-4">
                    <label htmlFor="subject" className="font-semibold">Subject<span className='text-accent'>*</span></label>
                    <input
                      type="text"
                      id="subject"
                      {...register('subject', { required: true })}
                      className="mt-1 p-4 rounded-md border-none text-textSecondary focus:ring-0"
                    />
                    {errors.subject && <span className="text-secondary">Subject is required</span>}
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <label htmlFor="message" className="font-semibold">Message<span className='text-accent'>*</span></label>
                  <textarea
                    id="message"
                    {...register('message', { required: true })}
                    className="mt-1 p-2 rounded-md border-none text-textSecondary focus:ring-0"
                    rows="5"
                  ></textarea>
                  {errors.message && <span className="text-secondary">Message is required</span>}
                </div>
                <div className="flex items-center mt-6 ">
                  <input
                    type="checkbox"
                    id="consent"
                    {...register('consent', { required: true })}
                    className="mr-2 text-primary focus:ring-0"
                  />
                  <label htmlFor="consent" className="">I consent to the privacy policy</label>
                  {errors.consent && <span className="text-secondary">Consent is required</span>}
                </div>
                <button
                  type="submit"
                  className="mt-4 bg-secondary hover:bg-secondary text-white font-semibold py-3 px-6 rounded"
                >
                  Send Message
                </button>
              </form>
            )}
          </div>
        </div>
      </section>
      </ScrollAnimation>

      {/* Webacha Section */}
      <section className="bg-background py-16 text-center mx-auto">
        <h3 className="md:text-4xl text-3xl font-bold text-primary mb-4">Webacha Company Limited</h3>
        <p className='text-lg text-textSecondary'>Innovation. Conservation. And Livelihood</p>
        <div className="flex justify-center items-center mt-6">
          <img src='images/logo.jpg' alt='logo' className='h-20 w-20 rounded-full' />
      </div>
      </section>
    </div>
  );
};

export default ContactPage;
