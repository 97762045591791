import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { FaArrowRight } from 'react-icons/fa';
import Testimonials from '../components/Testimonials';
import ContactSection from '../components/Contact';
import { useState } from 'react';
import ScrollAnimation from '../components/ScrollAnimation';

const Services = () => {
  // Use Intersection Observer to handle lazy loading of components when they come into view
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });
  const [ref6, inView6] = useInView({ triggerOnce: true });

  const [readMore, setReadMore] = useState({
    card1: false,
    card2: false,
    card3: false,
    card4: false,
    card5: false,
    card6: false,
  });

  const toggleReadMore = (card) => {
    setReadMore((prevState) => ({
      ...prevState,
      [card]: !prevState[card],
    }));
  };

  return (
    <div className="container mx-auto">
      {/* Header Section */}
      <header className="relative bg-fit bg-center text-white py-40 text-center " style={{ backgroundImage: 'url(/images/furniture3.jpeg)' }}>
        <div className="absolute inset-0 bg-gradient-to-l from-primary via-primary to-secondary opacity-50">
          {/* <img src="images/bamboo1.jpg" alt="Header Background" className="w-full h-full object-cover" /> */}
        </div>
        <div className="relative">
          <h1 className="text-3xl md:text-6xl font-bold">Our Services</h1>
        </div>
      </header>
    
      {/* Our Solutions */}
      <ScrollAnimation>
      <section className="my-12 px-4 sm:px-8 md:px-16">
        <h2 className="text-4xl font-bold text-center mb-8 text-primary">
          <span className="text-accent">Our</span> Solutions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-12">
          <div className="service-card bg-background shadow-lg rounded-xl mb-4">
            <div className="mb-8">
              <img src="images/seedling.jpeg" alt="seedling" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <h3 className="text-xl font-semibold text-primary px-4 sm:px-6">Bamboo Nurseries</h3>
            <p className="text-textSecondary py-4 sm:py-6 px-4 sm:px-6">
              At Webacha, we take pride in our state-of-the-art bamboo nurseries. We cultivate a diverse
              range of bamboo species, ensuring the highest quality and sustainability. 
              {readMore.card1 && (
                <>
                  Our nurseries are designed to provide optimal growth conditions, enabling us to supply robust bamboo
                  seedlings to meet the demands of our plantations and clients. We guarantee the best start for
                  every bamboo plant. We have a dedicated team of experts who carefully monitor the growth and health of each
                  seedling, ensuring they are strong and ready for transplantation. Our commitment to
                  sustainability and quality is evident in every plant we grow.
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore('card1')} className="text-primary py-2 px-6 font-semibold flex items-center hover:text-accent">
              {readMore.card1 ? 'Read Less' : 'Read More'}
              <FaArrowRight className="ml-4 rounded-full text-primary text-sm hover:text-accent" />
            </button>
          </div>

          {/* Repeat the same structure for other service cards */}
          
          <div className="service-card bg-background shadow-lg rounded-xl mb-4">
            <div className="mb-8">
              <img src="images/bamboo11.jpeg" alt="plantation" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <h3 className="text-xl font-semibold text-primary px-4 sm:px-6">Bamboo Plantation</h3>
            <p className="text-textSecondary py-4 sm:py-6 px-4 sm:px-6">
              We establish and manage expansive bamboo plantations, transforming vast areas into
              productive and eco-friendly landscapes.
              {readMore.card2 && (
                <>
                  Our plantations are meticulously maintained to
                  maximize growth and yield. By utilizing sustainable farming practices, we ensure that our
                  bamboo plantations contribute to carbon sequestration, soil stabilization, biodiversity
                  enhancement and ensuring a renewable source of bamboo that supports both our ecological
                  and economic goals.
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore('card2')} className="text-primary py-2 px-6 font-semibold flex items-center">
              {readMore.card2 ? 'Read Less' : 'Read More'}
              <FaArrowRight className="ml-4 rounded-full text-primary text-sm" />
            </button>
          </div>

          {/* Repeat for other cards */}
          <div className="service-card bg-background shadow-lg rounded-xl mb-4">
            <div className="mb-8">
              <img src="images/furniture10.jpeg" alt="processing" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <h3 className="text-xl font-semibold text-primary px-4 sm:px-6">Bamboo Processing & Manufacturing</h3>
            <p className="text-textSecondary py-4 sm:py-6 px-4 sm:px-6">
              We excel in bamboo processing and manufacturing. Our facility is equipped with technology
              to transform raw bamboo into a wide array of products.
              {readMore.card3 && (
                <>
                  From initial treatment and
                  preservation to intricate processing techniques, we maintain stringent quality control to
                  deliver superior bamboo products. Our manufacturing process is designed to minimize waste and maximize efficiency, ensuring
                  that we produce high-quality products that meet the needs of our customers.
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore('card3')} className="text-primary py-2 px-6 font-semibold flex items-center">
              {readMore.card3 ? 'Read Less' : 'Read More'}
              <FaArrowRight className="ml-4 rounded-full text-primary text-sm" />
            </button>
          </div>

          {/* Repeat for other cards */}
          <div className="service-card bg-background shadow-lg rounded-xl mb-4">
            <div className="mb-8">
              <img src="images/furniture11.jpeg" alt="products" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <h3 className="text-xl font-semibold text-primary px-4 sm:px-6">Bamboo Products</h3>
            <p className="text-textSecondary py-4 sm:py-6 px-4 sm:px-6">
              Our diverse range of bamboo products showcases the versatility and sustainability of
              bamboo. From construction materials and furniture to décor items, bamboo fashion,
              disposable cutlery and everyday items, Webacha offers eco-friendly alternatives that cater
              to various industries.
              {readMore.card4 && (
                <>
                  We are committed to innovation and quality, providing products that
                  meet the highest standards of performance and environmental responsibility.
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore('card4')} className="text-primary py-2 px-6 font-semibold flex items-center">
              {readMore.card4 ? 'Read Less' : 'Read More'}
              <FaArrowRight className="ml-4 rounded-full text-primary text-sm" />
            </button>
          </div>

          {/* Repeat for other cards */}
          <div className="service-card bg-background shadow-lg rounded-xl mb-4">
            <div className="mb-8">
              <img src="images/furniture5.jpeg" alt="decor" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <h3 className="text-xl font-semibold text-primary px-4 sm:px-6">Bamboo Decor</h3>
            <p className="text-textSecondary py-4 sm:py-6 px-4 sm:px-6">
              Get your home decor from wall decor to your interior design. Define your requirements and let us achieve just that...
              {readMore.card5 && (
                <>
                  We offer customized solutions to match your style and preferences, ensuring that your space reflects your personality and taste.
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore('card5')} className="text-primary py-2 px-6 font-semibold flex items-center">
              {readMore.card5 ? 'Read Less' : 'Read More'}
              <FaArrowRight className="ml-4 rounded-full text-primary text-sm" />
            </button>
          </div>

          {/* Repeat for other cards */}
          <div className="service-card bg-background shadow-lg rounded-xl mb-4">
            <div className="mb-8">
              <img src="images/furniture6.jpeg" alt="product" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <h3 className="text-xl font-semibold text-primary px-4 sm:px-6">Biodegradable Cutlery</h3>
            <p className="text-textSecondary py-4 sm:py-6 px-4 sm:px-6">
              Climate conservation is our priority and therefore we equip your kitchen with beautifully made cutlery...
              {readMore.card6 && (
                <>
                  Our biodegradable cutlery is not only eco-friendly but also stylish and functional, perfect for any kitchen or dining setting.
                </>
              )}
            </p>
            <button onClick={() => toggleReadMore('card6')} className="text-primary py-2 px-6 font-semibold flex items-center">
              {readMore.card6 ? 'Read Less' : 'Read More'}
              <FaArrowRight className="ml-4 rounded-full text-primary text-sm" />
            </button>
          </div>
        </div>
      </section>
      </ScrollAnimation>
      {/* Call-to-Action Section */}
      <ScrollAnimation>
        <ContactSection />
      </ScrollAnimation>
      <ScrollAnimation>
        <Testimonials />
      </ScrollAnimation>
    </div>
  );
};

export default Services;
