import React from 'react';
import { FaShieldAlt, FaRocket, FaRegHandshake, FaTools, FaSolarPanel, FaClipboardList, FaFileSignature, FaCheck, FaPowerOff, FaEye, FaLeaf, FaGlobe, FaSun, FaArrowRight } from 'react-icons/fa';
import service1 from '../images/dinner1.jpeg';
import service2 from '../images/beauty.jpeg';
import service3 from '../images/furniture9.jpeg';
import ContactSection from '../components/Contact';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import ScrollAnimation from '../components/ScrollAnimation';  // Import the ScrollAnimation component

const services = [
  { title: 'Dining Set', description: 'Get your Dinner Set. strong and long-lasting furniture from webacha .', image: service1 },
  { title: 'Beauty Products', description: 'Elevate your home aesthetics with 100% bamboo made furniture.', image: service2 },
  { title: 'Wall Decors', description: 'With great art and design, we make your home a peaceful and soothing place with bamboo.', image: service3 },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const headerSettings = {
  dots: true,
  infinite: true,
  speed: 2000, // Slower transition speed
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500, // Slower autoplay speed
  fade: true,
  cssEase: 'linear',
};

const headerImages = [
  '/images/about.jpeg',
  '/images/about1.jpeg',
  '/images/item8.jpeg',
  '/images/item12.jpeg',
  '/images/item7.jpeg',
  '/images/bamboo9.jpeg',
  '/images/bamboo8.jpeg',

];

const AboutUs = () => {
  return (
    <div className="container mx-auto px-">
      {/* HEADER SECTION */}
      <header className="relative text-white text-center overflow-hidden">
        <Slider {...headerSettings}>
          {headerImages.map((image, index) => (
            <div key={index} className="relative">
              <img src={image} alt={`Slide ${index}`} className="w-full h-64 md:h-96 object-cover object-center" />
              <div className="absolute inset-0 bg-gradient-to-l from-primary via-primary to-secondary  opacity-25"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
                <h1 className="text-3xl md:text-6xl font-bold">About Us</h1>
              </div>
            </div>
          ))}
        </Slider>
      </header>

      {/* Company Overview */}
      <ScrollAnimation>
        <section className="py-8 md:py-12 bg-white text-center">
          <div className="container mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-16 text-primary md:text-center text-left md:px-0 px-4"><span className='text-accent'>Company</span> Overview</h2>
            <div className="items-center px-4 md:px-14 text-left mb-8 md:mb-12 text-textSecondary">
              <p>Webacha Co. Ltd. is a for-profit company with a strong social and environmental agenda. We are in the business of growing, processing, and selling bamboo. At a time when the eco-friendliness of products is a primary concern of consumers, Webacha is innovatively answering to the same functional demands of the day-to-day life products while incorporating greener ways and not sacrificing usability.</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 px-4 md:px-16">
              <div>
                <h3 className="font-bold mb-2 md:mb-4 text-primary text-left">Our Climate Impact Qualifying Activities</h3>
                <p className="mb-4 md:mb-8 text-left text-textSecondary">
                  "Webacha's activities are an exemplary case of a Climate Impact Qualifying Activities (CIQA) due to their substantial contributions to climate change mitigation, soil health improvement, water quality enhancement, carbon in value added products and environmental awareness and sustainable economic development. We're at the forefront of unleashing bamboo's remarkable potential to create a positive impact on both people and our precious planet."
                </p>
                <p className='text-primary font-bold text-left mb-4 md:mb-6'><p className="text-primary font-bold"><span className='text-accent'>Daniel W. Wephukulu</span> -  Chairman & Co-founder.</p></p>
              </div>
              <div>
                <h3 className="font-bold mb-2 md:mb-4 text-left text-primary">Bamboo Nurseries</h3>
                <p className='mb-4 md:mb-8 text-left text-textSecondary'>At Webacha, we take pride in our state-of-the-art bamboo nurseries. We cultivate a diverse range of bamboo species, ensuring the highest quality and sustainability. Our nurseries are designed to provide optimal growth conditions, enabling us to supply robust bamboo seedlings to meet the demands of our plantations and clients. We guarantee the best start for every bamboo plant. </p>
              </div>
              <div>
                <h3 className="font-bold mb-2 md:mb-4 text-left text-primary">Bamboo Plantations</h3>
                <p className='mb-4 md:mb-8 text-left text-textSecondary'>We establish and manage bamboo plantations, transforming vast areas into productive and eco-friendly landscapes. Our plantations are meticulously maintained to maximize growth and yield. By utilizing sustainable farming practices, we ensure that our bamboo plantations contribute to carbon sequestration, soil stabilization, biodiversity enhancement and ensuring a renewable source of bamboo that supports both our ecological and economic goals.</p>
              </div>
              <div>
                <h3 className="font-bold mb-2 md:mb-4 text-left text-primary">Bamboo Processing & Manufacturing</h3>
                <p className='mb-4 md:mb-8 text-left text-textSecondary'>We excel in bamboo processing and manufacturing. Our facility is equipped with technology to transform raw bamboo into a wide array of products. From initial treatment and preservation to intricate processing techniques, we maintain stringent quality control to deliver superior bamboo products. Our manufacturing process is designed to minimize waste and maximize efficiency, ensuring that we produce high-quality products that meet the needs of our customers.</p>
              </div>
            </div>
            <ScrollAnimation>
              <div className="py-8 md:py-14 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
                <button className="md:mb-0 mb-4 bg-accent text-white py-4 px-6 md:px-8 rounded font-semibold flex items-center text-sm">
                  Our Services <FaArrowRight className="ml-6 md:ml-4 bg-white rounded-full text-accent text-sm" />
                </button>
                <button className="bg-primary text-white py-4 px-7 md:px-8 rounded font-semibold flex items-center text-sm">
                  Contact Us <FaArrowRight className="ml-6 md:ml-4 bg-white rounded-full text-primary text-sm" />
                </button>
              </div>
            </ScrollAnimation>
          </div>
        </section>
      </ScrollAnimation>

      {/* Mission and Vision */}
      <ScrollAnimation>
        <section className="py-8 md:py-12 text-center bg-gradient-to-b from-primary to-secondary">
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-1/2 p-2 md:p-4 text-white font-semibold">
                <FaRocket className="text-5xl md:text-7xl text-white mx-auto mb-4" />
                <h3 className="text-2xl md:text-3xl text-accent font-bold mb-4 md:mb-8">Mission</h3>
                <p className="px-4 md:px-8 mb-4 md:mb-6">We harness the incredible potential of bamboo to create a positive impact on both people and planet.</p>
              </div>
              <div className="w-full md:w-1/2 p-2 md:p-4 text-white font-semibold">
                <FaEye className="text-5xl md:text-7xl text-white mx-auto mb-4" />
                <h3 className="text-2xl md:text-3xl text-accent font-bold mb-4 md:mb-8">Vision</h3>
                <p className="px-4 md:px-8 mb-4 md:mb-6">Our vision is to Empower Sustainability and Eco-Friendly Living Through Bamboo Innovation.</p>
              </div>
            </div>
          </div>
        </section>
      </ScrollAnimation>

      {/* How We Work */}
      <ScrollAnimation>
        <section className="py-8 md:py-12 bg-white text-center">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-8 md:mb-12 text-primary"><span className='text-accent'>How</span> We Work</h2>
            <div className="flex flex-wrap justify-center px-2 md:px-4">
              <div className="w-full md:w-1/2 lg:w-1/4 p-2 md:p-4">
                <FaRegHandshake className="text-4xl md:text-5xl mx-auto mb-4 text-white bg-accent rounded-full" />
                <h3 className="text-lg md:text-xl font-bold mb-2 text-primary">Enquiry</h3>
                <p>We begin with an in-depth consultation to understand your needs and design requirements.</p>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2 md:p-4">
                <FaTools className="text-4xl md:text-5xl text-blue-800 mx-auto mb-4 text-white bg-accent rounded-full" />
                <h3 className="text-lg md:text-xl font-bold mb-2 text-primary">Product Design</h3>
                <p>Our talented team implements the design.</p>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2 md:p-4">
                <FaSolarPanel className="text-4xl md:text-5xl text-blue-800 mx-auto mb-4 text-white bg-accent rounded-full" />
                <h3 className="text-lg md:text-xl font-bold mb-2 text-primary">Shipping</h3>
                <p>The product is shipped to your location.</p>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2 md:p-4">
                <FaClipboardList className="text-4xl md:text-5xl text-blue-800 mx-auto mb-4 text-white bg-accent rounded-full" />
                <h3 className="text-lg md:text-xl font-bold mb-2 text-primary">Pick Up</h3>
                <p>Client picks the product.</p>
              </div>
            </div>
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation>
        <section className="py-16 md:py-24 bg-background text-center overflow-hidden">
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 text-left px-6">
              <h3 className="text-sm font-semibold text-accent md:text-left text-center">PARTNERS AND SPONSORS</h3>
            </div>
            <div className="w-full md:w-1/2 px-6">
              <Slider {...settings}>
                <div className="px-2">
                  <img
                    src="images/partner1.png"
                    alt="Partner 1"
                    className="mx-auto w-50 h-32 md:w-32 md:h-32 border border-gray-300 p-6 bg-white"
                  />
                </div>
                <div className="px-2">
                  <img
                    src="images/partner2.jfif"
                    alt="Partner 2"
                    className="mx-auto w-50 h-32 md:w-32 md:h-32 border border-gray-300 p-6 bg-white"
                  />
                </div>
                <div className="px-2">
                  <img
                    src="images/partner3.png"
                    alt="Partner 3"
                    className="mx-auto w-50 h-32 md:w-32 md:h-32 border border-gray-300 p-6 bg-white"
                  />
                </div>
                <div className="px-2">
                  <img
                    src="images/partner4.jpeg"
                    alt="Partner 4"
                    className="mx-auto w-50 h-32 md:w-32 md:h-32 border border-gray-300 p-6 bg-white"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </ScrollAnimation>

      {/* Contact Us */}
      <ScrollAnimation>
        <div>
          <ContactSection />
        </div>
      </ScrollAnimation>

      {/* Products */}
      <ScrollAnimation>
        <section id="services" className="py-12 md:py-24 bg-background overflow-hidden">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-primary"><span className='text-accent'>Best Of</span> Webacha</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
              {services.map((service, index) => (
                <div key={index} className="bg-secondary rounded-lg shadow-md">
                  <img src={service.image} alt={service.title} className="h-32 md:h-40 w-full rounded-t-lg object-cover mb-4 md:mb-6" />
                  <h3 className="text-xl md:text-2xl font-bold mb-2 md:mb-4 text-primary mx-4 md:mx-12">{service.title}</h3>
                  <p className="mb-4 md:mb-12 text-primary mx-4 md:mx-12">{service.description}</p>
                  <Link to="/services">
                    <button className="bg-primary text-white px-4 py-2 rounded-lg mb-4 md:mb-12 mx-4 md:mx-12">Learn More</button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default AboutUs;
