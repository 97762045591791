// src/components/Services.js

import React from 'react';
import service1 from  '../images/seedling.jpeg';
import service2 from  '../images/furniture8.jpeg';
import service3 from  '../images/furniture5.jpeg';
import ScrollAnimation from './ScrollAnimation';

const services = [
  { title: 'Bamboo Growing', description: 'There is no doubt that bamboo is becoming a new green commodity that will shape the future due to its exceptional properties..', image: service1 },
  { title: 'Bamboo Processing', description: 'We process with great efficiency and precision.', image: service2 },
  { title: 'Bamboo Products', description: 'We sell bamboo furniture, Jewelry and home decor.', image: service3 },
];

const Services = () => {
  return (
    <ScrollAnimation>
    <section id="services" className="py-16 bg-background overflow-hidden">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-16 text-primary"><span className='text-accent'> Our</span> Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-secondary rounded-lg shadow-md">
              <img src={service.image} alt={service.title} className="h-40 w-full rounded-t-lg object-cover mb-6 " />
              <h3 className="md:text-2xl text-xl font-bold mb-4 text-primary mx-12">{service.title}</h3>
              {/* <p className="mb-12 text-primary mx-12">{service.description}</p> */}
              {/* <button className="bg-primary text-white px-4 py-2 rounded-lg mb-12 mx-12">Learn More</button> */}
            </div>
          ))}
        </div>
      </div>
    </section>
    </ScrollAnimation>
  );
};

export default Services;
