import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const handleClick = (path) => {
    setActive(path);
    setMenuOpen(false);
  };

  const linkClasses = (path) => {
    return active === path
      ? 'text-accent border-b-4 border-accent'
      : 'hover:text-accent';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-20 py-2">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="text-2xl font-bold text-primary">
          <Link to ="/">
          <img src='images/logo.jpg' alt='logo' className='w-12 h-12 lg:w-16 lg:h-16 rounded-full' />
          </Link>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-primary">
            {menuOpen ? <FaTimes size={28} /> : <FaBars size={28} />}
          </button>
        </div>
        <ul className="hidden lg:flex lg:space-x-12 text-primary">
          <li className="mt-2 lg:mt-0 px-4 py-2 lg:p-0">
            <Link
              to="/"
              className={linkClasses('/')}
              onClick={() => handleClick('/')}
            >
              Home
            </Link>
          </li>
          <li className="mt-2 lg:mt-0 px-4 py-2 lg:p-0">
            <Link
              to="/about-us"
              className={linkClasses('/about')}
              onClick={() => handleClick('/about')}
            >
              About Us
            </Link>
          </li>
          <li className="mt-2 lg:mt-0 px-4 py-2 lg:p-0">
            <Link
              to="/services"
              className={linkClasses('/services')}
              onClick={() => handleClick('/services')}
            >
              Services
            </Link>
          </li>
          <li className="mt-2 lg:mt-0 px-4 py-2 lg:p-0">
            <Link
              to="/bamboo-products"
              className={linkClasses('/products')}
              onClick={() => handleClick('/products')}
            >
              Products
            </Link>
          </li>
          <li className="mt-2 lg:mt-0 px-4 py-2 lg:p-0">
            <Link
              to="/impact"
              className={linkClasses('/impact')}
              onClick={() => handleClick('/impact')}
            >
              Our Impact
            </Link>
          </li>
          <li className="mt-2 lg:mt-0 px-4 py-2 lg:p-0">
            <Link
              to="/contact"
              className={linkClasses('/contact')}
              onClick={() => handleClick('/contact')}
            >
              Contact
            </Link>
          </li>

        </ul>
      </div>

      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="absolute top-0 left-0 w-full bg-white shadow-md z-20"
          >
            <div className="container mx-auto px-4 py-2 flex justify-between items-center">
              <div className="text-2xl font-bold text-primary">
                <img src='images/logo.jpg' alt='logo' className='w-12 h-12 rounded-full' />
              </div>
              <button onClick={toggleMenu} className="text-primary">
                <FaTimes size={28} />
              </button>
            </div>
            <div className="container mx-auto px-4 py-2">
              <ul className="space-y-4 text-primary">
                <li>
                  <Link
                    to="/"
                    className={linkClasses('/')}
                    onClick={() => handleClick('/')}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    className={linkClasses('/about')}
                    onClick={() => handleClick('/about')}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className={linkClasses('/services')}
                    onClick={() => handleClick('/services')}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/bamboo-products"
                    className={linkClasses('/products')}
                    onClick={() => handleClick('/products')}
                  >
                    Products
                  </Link>
                </li>
                <li>
                  <Link
                    to="/impact"
                    className={linkClasses('/impact')}
                    onClick={() => handleClick('/impact')}
                  >
                    Our Impact
                  </Link>
          </li>
                <li>
                  <Link
                    to="/contact"
                    className={linkClasses('/contact')}
                    onClick={() => handleClick('/contact')}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
