import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import ScrollAnimation from './ScrollAnimation';

const FollowUs = () => {
  return (
    <ScrollAnimation>
    <div className="py-16 overflow-hidden bg-background">
      <h2 className="text-3xl font-bold text-center mb-6 text-primary"><span className='text-accent'>Follow Us</span> On Socials</h2>
      <p className="text-center text-gray-600 mb-12">
        Follow us on social media for updates, new products, and queries.
      </p>
      <div className="flex justify-center space-x-8 ">
        <a href="https://facebook.com/webacha" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-secondary transition duration-300">
          <FaFacebook className="text-3xl" />
        </a>
        <a href="https://x.com/WebachaS" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-secondary transition duration-300">
          <FaTwitter className="text-3xl" />
        </a>
        <a href="https://www.instagram.com/webacha_limited/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-secondary transition duration-300">
          <FaInstagram className="text-3xl" />
        </a>
        <a href="https://www.linkedin.com/company/webacha-company-limited/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-secondary transition duration-300">
          <FaLinkedin className="text-3xl" />
        </a>
        <a href="https://www.tiktok.com/@webacha_bamboo" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-secondary transition duration-300">
          <FaTiktok className="text-3xl" />
        </a>
      </div>
    </div>
    </ScrollAnimation>
  );
};

export default FollowUs;
